import React from 'react'
import Layout from '../components/layout'
import { Html } from '../components/html'
import H2 from '../theming/H2'
import { useAgency } from '../hooks/use-agency'
import { FormattedMessage } from 'react-intl'

export default (props) => {
  const { locale } = props.pageContext
  const { settings } = useAgency()

  return (
    <Layout {...props} title="page.about">
      <div>
        <div className="c-row c-row--alpha">
          <div className="o-container">
            <div className="o-grid o-grid--gutter">
              <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp4">
                <H2 className="section-title--big">
                  <FormattedMessage id="page.about" />
                </H2>
              </div>
            </div>
          </div>
        </div>
        <div className="c-row c-row--alpha">
          <div className="o-container">
            <div className="o-grid o-grid--gutter">
              <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp4">
                <article className="c-rich-text">
                  <Html content={settings[`about_${locale}`]} isRichText />
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
